import React from 'react';

const Spinner = (props) => (
	<div className='spinner' style={props.style}>
		<div className='loader'>
			<div className='bounce1' />
			<div className='bounce2' />
			<div className='bounce3' />
		</div>
		<p>{props.text}</p>
	</div>
);

export default Spinner;