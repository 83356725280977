import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import Spinner from '../Spinner';
import Users from '../Users';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import NotFoundPage from '../404';
import * as ROUTES from '../../constants/routes';
import * as DATABASE from '../../constants/database';
 
class AdminPageBase extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			devotions: []
		};
	}

	pushDevotions = (event, source, destination) => {
		event.preventDefault();
		let confirmation = window.prompt(`This process will overwrite the the "${destination}" database with the "${source}" database and cannot be undone. Confirm you want to proceed by typing the destination database id: ${destination}`);

		if (!confirmation) {
			return;
		} else if (confirmation === destination) {
			this.props.firebase.doPushDevotions(
				source, 
				destination, 
				alert(`Devotions successfully pushed from ${DATABASE.DEV} to ${DATABASE.PROD}.`)
			);
		} else {
			alert('Incorrect password. Process canceled.');
		}
	}

	deleteHandler = (event, id) => {
		event.preventDefault();
		let confirmation = window.confirm("Are you sure you want to delete this devotion?");

		if (confirmation) {
			this.props.firebase.doRemoveDevotion(id);
		}
	};

	componentDidMount() {
		this.setState({ loading: true });

		this.props.firebase.devotions().get().then((snapshot) => {
			const devotionsList = [];
			
			snapshot.forEach((doc) => {
				devotionsList.push({
					...doc.data(),
					id: doc.id
				});
			});

			this.setState({
				devotions: devotionsList,
				loading: false
			});
		});
	}
	
	render() {
		const { devotions, loading } = this.state;

		return (
			<AuthUserContext.Consumer>
				{authUser => (
					<>
						{
							this.props.match.isExact ?
								<section>
									<div className="wrapper">
										<h1>Admin</h1>
										
										{ loading ?
											<Spinner /> :
											<>
												<AdminButtons />

												{ devotions.length > 0 ?
													<>
														<Devotions devotions={devotions} deleteHandler={this.deleteHandler} />
														<AdminButtons clickHandler={this.pushDevotions} />
													</>
													: null
												}
											</>
										}
									</div>
								</section>
								: null
						}

						<Switch>
							<Route exact path='/admin' />
							<Route exact path='/admin/users' component={Users} />
							<Route path='/admin/*' component={NotFoundPage} />
						</Switch>
					</>
				)}
			</AuthUserContext.Consumer>
		);
	}
}

const Devotions = ({ devotions, deleteHandler }) => {
	return (
		<ul className="admin-list">
			{devotions.map(devotion => (
				<li key={devotion.id}>
					<div className="row">
						<div className="data">
							<span><strong>{devotion.date}</strong></span>
						</div>
						
						<div className="buttons">
							<Link to={`/devotions/${devotion.id}`}>
								<button title="View" type="button" className="view" />
							</Link>
							
							<Link to={`/edit/${devotion.id}`}>
								<button title="Edit" type="button" className="edit" />
							</Link>

							<Link to="#">
								<button title="Delete" type="button" className="danger" onClick={e => deleteHandler(e, devotion.id)} />
							</Link>
						</div>
					</div>
				</li>
			))}
		</ul>
	);
};

const AdminButtons = ({ clickHandler }) => (
	<span className="flex-buttons">
		<span>
			<Link to={ROUTES.CREATE}>
				<button type="button">
					Create Devotion	
				</button>
			</Link>

			<Link to={ROUTES.USERS}>
				<button type="button">
					View Users
				</button>
			</Link>
		</span>

		{ clickHandler && process.env.NODE_ENV !== 'production' ? 
			<span>
				<button className="danger" type="submit" onClick={e => clickHandler(e, DATABASE.DEV, DATABASE.PROD)}>Push Devotions to Production</button>
			</span>
			: null
		}
	</span>
);

const AdminPage = withFirebase(AdminPageBase);

const condition = authUser => authUser && !!authUser.isAdmin;
 
export default withAuthorization(condition)(AdminPage);