import React, { Component } from 'react';
import InstantAdventDark from '../../images/instant_advent.png';
import InstantAdventLight from '../../images/instant_advent_light.png';
import { getToday, imageLoader } from '../../utils.js';

const Banner = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? InstantAdventLight : InstantAdventDark;

class WelcomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			imgLoaded: false
		}
	}

	componentDidMount() {
		imageLoader(Banner, this.setState.bind(this));
	}

	render() {
		let redirect = getToday().redirect;

		return (
			<>
				<section className="devotion">
					<div className="banner banner--logo">
						<div
							className="banner-image"
							style={{
								transition: "ease 1s opacity",
								opacity: `${this.state.imgLoaded ? "1" : "0"}`
							}}
						>
							<img src={Banner} alt="Instant Advent" style={{ width: '500px' }} />
						</div>
					</div>
					<div className="devotion-parent">
						<div className="devotion-header">
							<h1 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
								<strong style={{ fontSize: '1rem', textTransform: 'uppercase', color: 'var(--primary)' }}>Question</strong><br/>
								How can kids and families really get ready <span className="no-wrap">to celebrate Jesus&rsquo; birth?</span>
							</h1>
							<h1 style={{ fontSize: '1.5rem' }}>
								<strong style={{ fontSize: '1rem', textTransform: 'uppercase', color: 'var(--primary)' }}>Answer</strong><br/>
								With Instant Advent from Sparkhouse!
							</h1>
						</div>
						<div className="devotion-body info-page">
							<p>Hey moms and dads, kids and cousins, Otto here.</p>
 
							<p>Remember me? How could you not?!? I&rsquo;m like the leading man&mdash;ahem&mdash;I mean boy from the Whirl videos that kids love in that <a href="https://www.wearesparkhouse.org">Sparkhouse Sunday School</a>.</p>
							
							<p>There are lots more of us. Characters that is—Ada and Gabe and Mimi and Samson. Samson? You heard me right. Samson and Mary and Martha. And of course, Jesus! Lots and lots of Jesus!</p>
							
							<p>But wait, I&rsquo;m getting ahead of myself. It&rsquo;s true—there&rsquo;s lots of great characters and lots of great videos in the new <a href="https://www.youtube.com/channel/UCZbRM0U2uo9yzpnR3cmtgGg">Sparkhouse Family YouTube Channel</a>&mdash;not just Whirl. Check it out.</p>

							<p>Right now, I&rsquo;m here to talk about&mdash;TADA!&mdash;<em><strong>Instant Advent</strong></em>! On November 27, the first Sunday in Advent in 2022, this Sparkhouse app will explode with lots of awesome stuff for the whole family. You will find daily devotionals, Bible readings, activity sheets, and specially remastered Sparkhouse Family Instant Advent Whirl videos featuring me and my friends. 29 days of videos for everyone you know! One for EVERY day of Advent, including Christmas Day too!</p>
							
							<p>Watch in the car, at the airport, over the river, and through the woods on the way to grandma&rsquo;s house. You can even watch it late at night… You know, when you just can&rsquo;t sleep because CHRISTMAS IS COMING!!!</p>
							
							<p>You get the idea. <em><strong>Instant Advent</strong></em>. Right here. Right now. Wherever. Starting November 27, 2022, that is.</p>
							
							<p>Bye for now.</p>
							
							<p>Oh, and by the way, for lots of Sparkhouse fun today, subscribe to the NEW <a href="https://www.youtube.com/channel/UCZbRM0U2uo9yzpnR3cmtgGg">Sparkhouse Family YouTube Channel</a>, like it, follow it, and share it with your friends, cousins, and everyone else.</p>

							{ redirect &&
								<div className="actions">
									<a className="cta" href="/">Go to today&rsquo;s devotion</a>
								</div>
							}

							<h4>The Fine Print:</h4>
							
							<ul className="credits">
								<li>Sparkhouse Family video resources are designed for home use. For a license to use our videos in church, school, or other group settings, please start your subscription to <a href="https://sparkhousedigital.org/">Sparkhouse Digital</a> today!</li>
							</ul>
						</div>
					</div>
				</section>
			</>
		);
	};
};

export default WelcomePage;
