import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import SignOutButton from '../SignOut';
 
const AccountPage = () => (
	<AuthUserContext.Consumer>
		{authUser => (
			<section className="account">
				<div className="wrapper">
					<h1>Account</h1>
					<h2>{authUser.email}</h2>
					{ authUser.isAdmin ? <h2>Admin</h2> : null }
					<PasswordChangeForm />
					<SignOutButton />
				</div>
			</section>
		)}
	</AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;
 
export default withAuthorization(condition)(AccountPage);