import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import RichTextEditor from 'react-rte';
import { ChromePicker } from 'react-color'

import Spinner from '../Spinner';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const CreatePage = () => {
	return(
		<section>
			<div className="wrapper">
				<h1>Create</h1>
				<CreateForm />
			</div>
		</section>
	);
};

const INITIAL_STATE = {
	id: '',
	date: '',
	title: '',
	reading: '',
	imgUrl: '',
	color: '',
	videoUrl: '',
	text: RichTextEditor.createEmptyValue(),
	error: null
};

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

class CreateFormBase extends Component {
	constructor(props) {
		super(props);

		if(props.devotionId) {
			this.state = {
				edit: props.edit,
				id: props.devotionId,
				date: props.date,
				title: props.title,
				reading: props.reading,
				imgUrl: props.imgUrl,
				color: props.color,
				videoUrl: props.videoUrl,
				text: RichTextEditor.createValueFromString(props.text, 'html')
			};
		} else {
			this.state = { ...INITIAL_STATE };
		}
	}

	submitForm = (imgUrl = '') => {
		const { 
			id,
			date,
			title,
			reading,
			color,
			videoUrl,
		} = this.state;

		const text = this.state.text.toString('html');

		this.props.firebase
			.devotion(id)
				.set({
					date,
					title,
					reading,
					color,
					imgUrl,
					videoUrl,
					text
				})
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				this.props.history.push(ROUTES.ADMIN);
			})
			.catch(error => {
				this.setState({ error });
			});
	};

	onSubmit = event => {
		event.preventDefault();

		if (document.querySelectorAll('input[type=file]')[0].files.length) {
			this.onImageSubmit(this.state.id);
		} else {
			this.submitForm();
		}
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onIdChange = event => {
		this.onChange(event);

		const dateArray = (event.target.value).split('-');
		const monthNumber = dateArray[1] ? dateArray[1] : '';
		const month = MONTHS[parseInt(monthNumber) - 1];
		const day = dateArray[2] ? parseInt(dateArray[2]) : '';
		
		if (month) {
			this.setState({ date: `${month + ' ' + day}` });
		} else {
			this.setState({ date: '' });
		}
	}

	onRteChange = value => {
		this.setState({
			text: value
		});
		if (this.props.onChange) {
			this.props.onChange(
				value.toString('html')
			);
		}
	};

	onColorChange = color => {
		this.setState({
			color: color.hex
		})
	}

	onColorFieldChange = event => {
		if (event.target.value.length && event.target.value.charAt(0) !== '#') {
			event.target.value = "#" + event.target.value;
		}

		this.setState({ [event.target.name]: event.target.value });
	}

	deleteHandler = (event, id) => {
		event.preventDefault();
		let isConfirmed = window.confirm("Are you sure you want to delete this devotion?");

		if (isConfirmed) {
			this.props.firebase
				.doRemoveDevotion(id)
				.then(() => {
					this.setState({ ...INITIAL_STATE });
					this.props.history.push(ROUTES.ADMIN);
				})
				.catch(error => {
					this.setState({ error });
				});
		}
	}

	onImageSubmit = id => {
		this.setState({ imgUploading: true });

		const file = document.querySelectorAll('input[type=file]')[0];

		if (file.files.length) {
			this.props.firebase.doCheckImage(id, file.files[0], function() {
				this.setState({
					error: {'message': 'Filename already exists.'},
				});

				file.value = '';
			}.bind(this), function() {
				try {
					this.props.firebase
						.doImageUpload(id, file.files[0], function(url) {
							this.setState({
								imgUrl: url,
								imgUploading: false
							}, this.submitForm(url));
						}.bind(this));
					
					this.setState({ error: '' });
				} catch (error) {
					this.setState({ error });
				}
			}.bind(this));
		}
	}

	onDeleteImage = (event, id, imgUrl, callback) => {
		event.preventDefault();
		this.setState({ imgUploading: true });

		let isConfirmed = window.confirm("Are you sure you want to delete this image?");

		if (isConfirmed) {
			if (!id) return;

			try {
				this.props.firebase
					.doUpdateImage(id, '', callback);
				
				this.props.firebase
					.doImageDelete(imgUrl);

				this.setState({ imgUploading: false });
			} catch (error) {
				this.setState({ error });
			}
		}
	}

	imageCallback = (value) => {
		this.setState({
			imgUrl: value
		})
	}

	render() {
		const {
			edit,
			id,
			date,
			title,
			reading,
			videoUrl,
			imgUrl,
			imgUploading,
			color,
			text,
			error
		} = this.state;

		const isInvalid =
			id === '' ||
			date === '' ||
			text === '';

		return (
			<div style={{ position: "relative" }}>
				{ imgUploading ? <Spinner text="Submitting form" style={{ position: "absolute", top: "37%", zIndex: "2" }} /> : null }

				<form name="create-form" className={ imgUploading ? "create-form disabled" : "create-form" } onSubmit={this.onSubmit}>					
					{
						imgUrl ?
							<>
								<img src={imgUrl} style={{ marginBottom: "1rem", width: "100%" }} alt="banner" />
								<div className="delete-image" style={{ display: "flex", justifyContent: "flex-end" }}>
									<button
										className="danger"
										onClick={e => this.onDeleteImage(e, id, imgUrl, this.imageCallback(''))}
									>
											Delete Image
									</button>
								</div>
							</>
						:
							<>
								<div className="field">
									<input name="file" type="file" accept="image/*" />
									<label htmlFor="file">File</label>
								</div>
							</>
					}

					<input
						name="imgUrl"
						value={imgUrl}
						onChange={this.onChange}
						type="hidden"
						placeholder="Image URL"
					/>
					<div className="field">
						<input
							name="id"
							value={id}
							onChange={this.onIdChange}
							type="date"
							placeholder="Date (e.g. 2020-11-29)"
							disabled={edit}
							style={{ minHeight: "44px" }}
							required
						/>
						<label htmlFor="id">Date*</label>
					</div>
					<div className="field">
						<input
							name="date"
							value={date}
							type="text"
							disabled={true}
						/>
						<label htmlFor="date">Display date</label>
					</div>

					<div className="flex-form">
						<div className="fields">
							<div className="field">
								<input
									name="title"
									value={title}
									onChange={this.onChange}
									type="text"
									required
								/>
								<label htmlFor="title">Title*</label>
							</div>
							<div className="field">
								<input
									name="reading"
									value={reading}
									onChange={this.onChange}
									type="text"
								/>
								<label htmlFor="reading">Reading</label>
							</div>
							<div className="field color-field">
								<input
									name="color"
									value={color}
									onChange={this.onColorFieldChange}
									type="text"
									placeholder="#FFFFFF"
									minLength={4}
									maxLength={7}
									pattern="#[A-Za-z0-9-]{3,6}"
								/>
								<label htmlFor="color">Color</label>
								<span className="color-span" style={{ backgroundColor: `${color}` }} />
							</div>
							<div className="field">
								<input
									name="videoUrl"
									value={videoUrl}
									onChange={this.onChange}
									type="url"
								/>
								<label htmlFor="videoUrl">Video URL</label>
							</div>
						</div>
						<div className="picker">
							<ChromePicker
								color={color}
								onChange={this.onColorChange}
								disableAlpha={true}
							/>
						</div>
					</div>

					<RichTextEditor
						value={text}
						onChange={this.onRteChange}
						placeholder="Body text"
						className="rte"
						editorClassName="rte-editor"
						required
					/>
					
					{edit ?
						<span className="flex-buttons">
							<span>
								<button disabled={isInvalid} type="submit">Update</button>

								<button type="button" onClick={() => this.props.history.push(`../devotions/${id}`)}>
									View Devotion
								</button>

								<button type="button" onClick={() => this.props.history.push(ROUTES.ADMIN)}>
									Cancel
								</button>
							</span>

							<span>
								<button type="button" className="danger" onClick={e => this.deleteHandler(e, id)}>
									Delete Devotion
								</button>
							</span>
						</span>
						: <span className="flex-buttons create">
							<button disabled={isInvalid} type="submit">Submit</button>
							<button onClick={() => this.props.history.push(ROUTES.ADMIN)}>
								Cancel
							</button>
						</span>
					}

					{error && <p className="error-message">{error.message}</p>}
				</form>
			</div>
		);
	}
}

const CreateForm = compose(
	withRouter,
	withFirebase
)(CreateFormBase);

const condition = authUser => authUser && !!authUser.isAdmin;
 
export default withAuthorization(condition)(CreatePage);

export { CreateForm };