import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Spinner from '../Spinner';
import { withAuthorization } from '../Session';
import { CreateForm } from '../Create';
import * as ROUTES from '../../constants/routes';

class EditPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			devotion: {},
			devotionId: this.props.match.params.devotionId
		};
	}

	componentDidMount() {
		this.setState({ 
			loading: true
		});

		this.props.firebase.devotion(this.state.devotionId).get().then((doc) => {
			const devotionObject = doc.data();

			if (devotionObject) {
				this.setState({
					edit: true,
					loading: false,
					devotion: devotionObject,
					color: devotionObject.color,
					date: devotionObject.date,
					title: devotionObject.title,
					reading: devotionObject.reading,
					imgUrl: devotionObject.imgUrl,
					videoUrl: devotionObject.videoUrl,
					text: devotionObject.text
				})
			} else {
				this.setState({
					redirect: true
				})
			}
		});
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={ROUTES.NOT_FOUND} />
		}

		const {
			edit,
			loading,
			devotionId,
			color,
			date,
			title,
			reading,
			imgUrl,
			videoUrl,
			text
		} = this.state;

		if (loading) {
			return (
				<section>
					<div className="wrapper">
						<h1>Edit</h1>
						<Spinner />
					</div>
				</section>
			);
		}

		return (
			<section>
				<div className="wrapper">
					<h1>Edit</h1>

					<CreateForm edit={edit} devotionId={devotionId} color={color} date={date} title={title} reading={reading} imgUrl={imgUrl} videoUrl={videoUrl} text={text} />
				</div>
			</section>
		);
	}
}

const condition = authUser => authUser && !!authUser.isAdmin;
 
export default withAuthorization(condition)(EditPage);