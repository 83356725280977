import React from 'react';
import LogoLight from '../../images/logo_sm_white.svg';
import LogoDark from '../../images/logo_sm.svg';

const Logo = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? LogoLight : LogoDark;

const Maintenance = () => (
	<main className="maintenance">
		<section>
			<div className="wrapper">
				<img className="logo" src={Logo} alt="Sparkhouse" />
				<h1>Sorry, we are currently down for maintenance.</h1>
				<h2>We&rsquo;ll be back shortly.</h2>
			</div>
		</section>
	</main>
);

export default Maintenance;