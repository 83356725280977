import React from 'react';
import File from '../../files/Instructions.pdf';
import EllipsisDark from '../../images/ellipsis.svg';
import EllipsisLight from '../../images/ellipsis_light.svg';
import ShareDark from '../../images/share.svg';
import ShareLight from '../../images/share_light.svg';

const Ellipsis = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? EllipsisLight : EllipsisDark;
const Share = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? ShareLight : ShareDark;

const HelpPage = () => (
	<>
		<section className="devotion">
			<div className="devotion-parent">
				<div className="devotion-header">
					<h1>Add the app to your phone or tablet</h1>
				</div>
				<div className="devotion-body info-page">
					<h3 style={{ marginBottom: 0 }}>Safari for Apple iOS</h3>

					<p>Use Safari with an Apple iOS device. You will need to manually add the devotional to your home screen. This will look and act like an app on your device allowing you to easily access the daily devotional.</p>

					<ol>
						<li>Open the Safari app.</li>
						<li>Open SparkhouseDevo.org.</li>
						<li>Click the Share Menu icon (<img src={Share} style={{ width: "17px", padding: "0 2px", transform: "translateY(2.5px)", display: "inline-block" }} alt="iOS share icon" />).</li>
						<li>Select &ldquo;Add to Home Screen&rdquo; and follow the instructions given.</li>
					</ol>

					<h3 style={{ marginBottom: 0 }}>Google Chrome for Android</h3>

					<p>Use Google Chrome with an Android device. When you visit SparkhouseDevo.org on a phone or tablet for the first time you should be prompted to &ldquo;Add Devotions to Home screen.&rdquo; Click the prompt to add the devotional to your device. This will look and act like an app on your device allowing you to easily access the daily devotional.</p>

					<p>If you do not get this prompt using Chrome, follow these instructions. Note: These instructions will only work on Android. For an iOS device, please use the Safari instructions above.</p>

					<ol>
						<li>Open the Chrome app.</li>
						<li>Open SparkhouseDevo.org.</li>
						<li>Tap the menu icon (<img src={Ellipsis} style={{ width: "20px", padding: "0 2px", transform: "translateY(-2.5px)", display: "inline-block" }} alt="Chrome menu icon" />) and tap &ldquo;Add to home screen.&rdquo;</li>
					</ol>

					<div className="actions">
						<a className="cta" href={File} download="Instructions.pdf">Download PDF instructions</a>
					</div>
				</div>
			</div>
		</section>
	</>
);

export default HelpPage;