import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active: false,
		};
	}

	menuClick = () => {
		this.setState(prevState => ({
			active: !prevState.active
		}));
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({ active: false });
		}
	}
	
	render() {
		return (
			<AuthUserContext.Consumer>
				{authUser =>
					authUser ? (
						<NavigationAuth authUser={authUser} menuClick={this.menuClick} active={this.state.active} />
					) : (
						<NavigationNonAuth menuClick={this.menuClick} active={this.state.active} />
					)
				}
			</AuthUserContext.Consumer>
		);
	}
}

const NavigationAuth = ({ authUser, menuClick, active }) => {
	return (
		<nav>
			<div className="logo">
				<Link to={ROUTES.WELCOME}>Home</Link>
			</div>

			<ul className={active ? "active" : null }>
				<li>
					<Link to={ROUTES.DEVOTIONS_LIST}>All Devotions</Link>
				</li>
				<li>
					<Link to={ROUTES.HELP}>Help</Link>
				</li>
				<li>
					<Link to={ROUTES.ACCOUNT}>Account</Link>
				</li>
				{!!authUser.isAdmin && (
					<li>
						<Link to={ROUTES.ADMIN}>Admin</Link>
					</li>
				)}
			</ul>

			<div className={active ? "menu active" : "menu" } onClick={menuClick} />
		</nav>
	);
};

const NavigationNonAuth = ({ menuClick, active }) => {
	return (
		<nav>
			<div className="logo">
				<Link to={ROUTES.WELCOME}>Home</Link>
			</div>
			
			<ul className={active ? "active" : null }>
				<li>
					<Link to={ROUTES.DEVOTIONS_LIST}>All Devotions</Link>
				</li>

				<li>
					<Link to={ROUTES.HELP}>Help</Link>
				</li>
			</ul>

			<div className={active ? "menu active" : "menu" } onClick={menuClick} />
		</nav>
	);
};

export default withRouter(Navigation);