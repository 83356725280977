import React from 'react';
import { Redirect } from 'react-router-dom';
import { getToday } from '../../utils.js';

const HomePage = () => {
	let today = getToday().today;
	let redirect = getToday().redirect;

	if (redirect) {
		return <Redirect to={`/devotions/${today}`} />
	} else {
		return <Redirect to={'/welcome'} />
	}
};

export default HomePage;