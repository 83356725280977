import React, { Component } from 'react';
import { Workbox } from 'workbox-window';

class Notification extends Component {
	constructor(props) {
		super(props);
		this.notification = React.createRef();
		this.refresh = React.createRef();
		this.dismiss = React.createRef();
	}

	componentDidMount() {
		if ('serviceWorker' in navigator) {
			window.addEventListener('load', () => {
				const wb = new Workbox('/service-worker.js');

				wb.addEventListener('waiting', (event) => {
					this.refresh.current.addEventListener('click', (event) => {
						wb.addEventListener('controlling', (event) => {
							window.location.reload();
						});
						
						wb.messageSW({type: 'SKIP_WAITING'});
					});
					
					this.dismiss.current.addEventListener('click', (event) => {
						this.notification.current.classList.remove('visible');
					});

					this.notification.current.classList.add('visible');
				});

				wb.register();
			});
		}
	}

	render() {
		return (
			<div id="notification" ref={this.notification}>
				<span>A new version is available.</span>
				<span style={{ whiteSpace: 'nowrap' }}>
					<button ref={this.refresh}>Refresh</button>
					<button ref={this.dismiss}>Dismiss</button>
				</span>
			</div>
		);
	}
}

export default Notification;