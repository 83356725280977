import * as DATES from './constants/dates';

export function getToday() {
	let redirect = true;
	let timeZoneOffset = new Date().getTimezoneOffset() * 60000;
	let today = new Date(Date.now() - timeZoneOffset).toISOString();

	if ((today < DATES.START_DATE + 'T00:00:00') || (today > DATES.END_DATE + 'T23:59:59')) {
		redirect = false;
	}

	today = today.split('T')[0];
	
	return {
		'today': today, 
		'redirect': redirect
	};
}

export function imageLoader(src, setState) {
	const imageLoader = new Image();
	imageLoader.src = src;

	imageLoader.onload = () => {
		if (setState) { 
			setState({ 
				imgLoaded: true
			});
		}
	}
}