export const HOME = '/';
export const WELCOME = '/welcome';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const USERS = '/admin/users';
export const PASSWORD_FORGET = '/reset';
export const DEVOTIONS_LIST = '/devotions';
export const DEVOTION = '/devotions/:devotionId';
export const CREATE = '/create';
export const EDIT = '/edit/:devotionId';
export const NOT_FOUND = '/404';
export const HELP = '/help';