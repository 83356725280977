import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';
import { Offline } from 'react-detect-offline';

import Notification from '../Notification';
import OfflineMessage from '../OfflineMessage';
import ScrollToTop from '../ScrollToTop';
import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import WelcomePage from '../Welcome';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DevotionsList from '../DevotionsList';
import CreatePage from '../Create';
import EditPage from '../Edit';
import NotFoundPage from '../404';
import HelpPage from '../Help';
import Maintenance from '../Maintenance';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
  
const App = () => {
	let maintenance = false;

	if (maintenance) {
		return (
			<>
				<Maintenance />
				<Notification />
			</>
		);
	} else {
		return (
			<Router>
				<ScrollToTop />
				<Navigation />

				<main>
					<Switch>
						<Route exact path={ROUTES.HOME} component={HomePage} />
						<Route exact path={ROUTES.WELCOME} component={WelcomePage} />
						<Route exact path={ROUTES.HELP} component={HelpPage} />
						<Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
						<Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
						<Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
						<Route path={ROUTES.ADMIN} component={AdminPage} />
						<Route path={ROUTES.DEVOTIONS_LIST} component={DevotionsList} />
						<Route exact path={ROUTES.CREATE} component={CreatePage} />
						<Route exact path={ROUTES.EDIT} component={EditPage} />
						<Route exact path={ROUTES.NOT_FOUND} component={NotFoundPage} />
						<Route component={NotFoundPage} />
					</Switch>
				</main>

				<Offline>
					<OfflineMessage />
				</Offline>

				<Notification />
			</Router>
		);
	}
};

export default withAuthentication(App);