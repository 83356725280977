import React, { Component } from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { getToday } from '../../utils.js';

import Spinner from '../Spinner';
import Devotion from '../Devotion';
import { withFirebase } from '../Firebase';

class DevotionsListBase extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			devotions: []
		};
	}

	componentDidMount() {
		this.setState({ loading: true });

		this.unsubscribe = this.props.firebase
			.devotions()
			.onSnapshot((snapshot) => {
				const devotionsList = [];
				
				snapshot.forEach((doc) => {
					devotionsList.push({
						...doc.data(),
						id: doc.id
					});
				});

				this.setState({
					devotions: devotionsList,
					loading: false
				});
			}, (error) => {
				console.log(error);
			});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		const { devotions, loading } = this.state;
		let { path, url } = this.props.match;
		let today = getToday().today;

		// filter to not show posts until it reaches corresponding date
		const devotionsFiltered = devotions.filter((item) => today >= item.id);

		// unfiltered
		const devotionsList = devotions.map((item) => (
			<li key={item.id}>
				<Link to={`${url}/${item.id}`}>{item.date}</Link>
			</li>
		));

		return (
			<>
				{
					this.props.match.isExact ?
						<section>
							<div className="wrapper">
								<h1>All Devotions</h1>

								{ loading ?
									<Spinner />
									:
									<div className='devotions-list'>
										{ !devotionsList || devotionsList.length === 0 ? <p>Devotions coming soon!</p> : <ul>{devotionsList}</ul> }
									</div>
								}
							</div>
						</section>
						: null
				}
				
				<Switch>
					<Route exact path={path} />
					<Route key={`:devotionId`} path={`${path}/:devotionId`} component={Devotion} />
				</Switch>
			</>
		);
	}
}

const DevotionsList = withFirebase(DevotionsListBase);
 
export default withRouter(DevotionsList);