import React, { Component, useRef } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactPlayer from 'react-player';

import Spinner from '../Spinner';
import { imageLoader } from '../../utils.js';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as DATES from '../../constants/dates';

const Day = (props) => {
	let history = useHistory();
	let clientX, clientY, deltaX, deltaY, scrollDir, opacity;

	const touchStart = (event) => {
		clientX = event.touches[0].clientX;
		clientY = event.touches[0].clientY;
	};

	const touchMove = (event) => {
		if (clientX < 15 || clientX > window.innerWidth - 15) return;

		deltaX = event.changedTouches[0].clientX - clientX;
		deltaY = event.changedTouches[0].clientY - clientY;

		if (!scrollDir) {
			if (Math.abs(deltaX) > Math.abs(deltaY)) {
				scrollDir = 'x';
			} else {
				scrollDir = 'y';
			}
		}

		if (scrollDir === 'x') {
			disableBodyScroll(event.target);
			opacity = 1 - Math.abs(deltaX / 200);

			if (!hasPrev && deltaX > 0) {
				return;
			} else if (!hasNext && deltaX < 0) {
				return;
			} else if (Math.abs(deltaX) > 30) {
				animateSwipe(opacity, deltaX);
			}
		}
	};

	const touchEnd = () => {
		if (scrollDir === 'x') {
			if (opacity > 0) {
				animateSwipe(1, 0);
			}

			if (deltaX >= 200 && hasPrev) {
				history.push(`/devotions/${props.prev}`);
			}

			if (deltaX <= -200 && hasNext) {
				history.push(`/devotions/${props.next}`);
			}
		}
		
		clearAllBodyScrollLocks();
		deltaX = 0;
		deltaY = 0;
		scrollDir = '';
	};

	const animateSwipe = (opacity, deltaX) => {
		const devotionParent = document.querySelector('.devotion-parent');
		devotionParent.style.opacity = opacity;

		if (deltaX === 0) {
			devotionParent.style.transform= 'none';
		} else {
			devotionParent.style.transform = `translateX(${deltaX}px)`;
		}
	};

	// filter out devotions that have not yet occurred
	// let today = getToday().today;
	// const hasNext = props.next && today >= props.next;
	// const hasPrev = props.prev && today >= props.prev;

	// unfiltered
	const hasNext = props.next;
	const hasPrev = props.prev;

	const videoRef = useRef();

	return (
		<>
			<div className="devotion-parent" onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd}>
				<div className="devotion-header">
					<h1>{props.date}</h1>
					<h2>{props.title}</h2>
				</div>

				{ props.imgUrl ? 				
					<div className="banner" style={{ backgroundColor: `${ props.color ? props.color : "#000" }` }}>
						<div
							className="banner-image"
							style={{
								backgroundImage: `url(${props.imgUrl})`,
								transition: "ease 1s opacity",
								opacity: `${ props.imgLoaded ? "1" : "0" }`
							}}
						/>
					</div>
					: null
				}

				{ props.videoUrl ?
					<div className="banner video">
						<div className="video" ref={videoRef}>
							<ReactPlayer
								url={props.videoUrl}
								className="video-player"
								controls={true}
								style={{ "opacity": 0, "transition": "ease 1s opacity" }}
								onReady={() => videoRef.current.querySelector(".video-player").style.opacity = "1" }
							/>
						</div>
					</div>
					: null
				}

				<div className="devotion-body">
					<div dangerouslySetInnerHTML={{ __html: props.text }} />
				</div>
			</div>

			<div className="devotion-links">
				{ hasPrev ? <Link to={`/devotions/${props.prev}`} className="prev">Previous</Link> : <span className="disabled prev">Previous</span> }
				<Link to={ROUTES.DEVOTIONS_LIST} className="view-all">View All Devotions</Link>
				{ hasNext ? <Link to={`/devotions/${props.next}`} className="next">Next</Link> : <span className="disabled next">Next</span> }
			</div>
		</>
	);
}

class Devotion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			devotion: {},
			devotionId: this.props.match.params.devotionId
		};
	}
		
	componentDidMount() {
		this.setState({ 
			loading: true
		});

		this.fetchData(this.state.devotionId);
	}

	componentDidUpdate(prevProps) {
		const currentId = this.props.match.params.devotionId;
		const prevId = prevProps.match.params.devotionId;
		
		if (prevId !== currentId) {
			this.setState({
				loading: true,
				devotionId: currentId 
			});
			this.fetchData(currentId);
		}
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	fetchData(id) {
		this.unsubscribe = this.props.firebase
			.devotion(id)
			.onSnapshot((doc) => {
				const devotionObject = doc.data();

				if (devotionObject) {
					imageLoader(devotionObject.imgUrl, this.setState.bind(this));

					this.setState({
						loading: false,
						devotion: devotionObject,
						color: devotionObject.color,
						date: devotionObject.date,
						title: devotionObject.title,
						reading: devotionObject.reading,
						imgUrl: devotionObject.imgUrl,
						videoUrl: devotionObject.videoUrl,
						text: devotionObject.text,
						next: getNext(id),
						prev: getPrev(id)
					})
				} else {
					this.setState({
						redirect: true
					})
				}
			}, (error) => {
				console.log(error);
			});
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={ROUTES.NOT_FOUND} />
		}

		const {
			loading,
			devotionId,
			color,
			date,
			title,
			reading,
			imgUrl,
			imgLoaded,
			videoUrl,
			text,
			next,
			prev
		} = this.state;

		if (loading) {
			return (
				<section>
					<div className="wrapper">
						<Spinner />
					</div>
				</section>
			);
		}

		if (devotionId) {
			return (
				<section className="devotion">
					<Day key={devotionId} date={date} title={title} reading={reading} imgUrl={imgUrl} imgLoaded={imgLoaded} color={color} videoUrl={videoUrl} text={text} next={next} prev={prev} />
				</section>
			);
		} else {
			return <Redirect to={ROUTES.DEVOTIONS_LIST} />
		}
	}
}

const getDay = (id, delta) => {
	const newDate = new Date(id + 'T00:00:00');
	newDate.setDate(newDate.getDate() + delta);

	return newDate.toISOString().split('T')[0];
}

const getNext = (id) => {
	if (id === DATES.END_DATE) {
		return null;
	} else {
		return getDay(id, 1);
	}
}

const getPrev = (id) => {
	if (id === DATES.START_DATE) {
		return null;
	} else {
		return getDay(id, -1);
	}
}

export default withFirebase(Devotion);